import PopOutModal from '@/components/molecules/legacy/PopOutModal/PopOutModal';
import {
  COMPARE_EVS,
  DEFAULT_COMPARISON_EV_DETAILS,
  SELECT_ANOTHER_EV,
  SELECT_FIRST_EV,
  SELECT_SECOND_EV,
  firstEVIconStyle,
  secondEVIconStyle,
  selectedFirstEVStyle,
  selectedSecondEVStyle,
} from '@/lib/constants';
import { GetCustomOrderModelsForMakeResponse } from '@/lib/schema/models/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EVDetailProps } from '@/types/compare-ev';
import { EVCompareModel } from '../EVCompareModel/EVCompareModel';
import { TrimListProps } from '../EVCompareTrimSelection/EVCompareTrimSelection';

interface EVComparisonChainModelProps {
  modalStep: number;
  goToNextModal: (step: number) => void;
  makes: string[];
  firstChoice?: EVDetailProps;
  onSubmit: (body: {
    selectedDefaultDetails: EVDetailProps;
    firstEV: EVDetailProps;
    secondEV: EVDetailProps;
  }) => Promise<boolean>;
  onClose?: () => void;
}

export const EVComparisonChainModel: React.FC<EVComparisonChainModelProps> = ({
  firstChoice,
  modalStep,
  goToNextModal,
  makes,
  onSubmit,
  onClose,
}) => {
  const [makeEVOptions, setMakeEVOptions] =
    useState<GetCustomOrderModelsForMakeResponse>({});
  const [selectedEVDefaultDetails, setSelectedEVDefaultDetails] = useState(
    DEFAULT_COMPARISON_EV_DETAILS
  );
  const [selectedFirstEVDetails, setSelectedFirstEVDetails] =
    useState<EVDetailProps>();

  const [selectedSecondEVDetails, setSelectedSecondEVDetails] = useState(
    DEFAULT_COMPARISON_EV_DETAILS
  );

  const firstEVToCompare = useMemo(() => {
    if (!selectedFirstEVDetails) {
      return firstChoice || DEFAULT_COMPARISON_EV_DETAILS;
    }
    return selectedFirstEVDetails;
  }, [firstChoice, selectedFirstEVDetails]);

  const [activeModelIndex, setActiveModelIndex] = useState<number>(-1);
  const [activeTrimIndex, setActiveTrimIndex] = useState<number>(-1);
  const [yearList, setYearList] = useState<string[]>([]);
  const [trimList, setTrimList] = useState<TrimListProps[]>([]);
  const [showComparisonDetailPage, setShowComparisonDetailPage] =
    useState<boolean>(false);
  const [showReset, setShowReset] = useState<boolean>(false);
  const [editEVAlertMessage, setEditEVAlertMessage] = useState<string>('');

  async function handleMakeClick(event: React.MouseEvent<HTMLDivElement>) {
    const make = event.target as HTMLElement;
    const { innerText } = make;
    setShowReset(true);
    setEditEVAlertMessage('');
    setSelectedEVDefaultDetails((prevState) => ({
      ...prevState,
      make: innerText,
    }));

    const response = await fetch(`/api/model/${innerText}`);
    if (response.ok) {
      const makeOptions = await response.json();
      setMakeEVOptions(makeOptions);
      const makeYearList = Object.keys(makeOptions).reverse().slice(0, 2);
      setYearList(makeYearList);
      setSelectedEVDefaultDetails((prevState) => ({
        ...prevState,
        year: makeYearList[0],
      }));
    }
  }

  function handleMakeChange() {
    setSelectedEVDefaultDetails(DEFAULT_COMPARISON_EV_DETAILS);
    if (showComparisonDetailPage) {
      setSelectedSecondEVDetails(DEFAULT_COMPARISON_EV_DETAILS);
    } else {
      setSelectedFirstEVDetails(DEFAULT_COMPARISON_EV_DETAILS);
    }
    setActiveModelIndex(-1);
    setActiveTrimIndex(1);
    setShowReset(false);
    setEditEVAlertMessage('');
  }

  function handleBadgeClick(year: string) {
    setSelectedEVDefaultDetails((prevState) => ({
      ...prevState,
      year,
      model: '',
    }));
    setActiveModelIndex(-1);
    setActiveTrimIndex(-1);
    setEditEVAlertMessage('');
  }

  async function handleModelClick(
    model: string,
    imageURL: string,
    index: number
  ) {
    setSelectedEVDefaultDetails((prevState) => ({
      ...prevState,
      model,
      imageURL,
      trim: '',
      MSRP: 0,
    }));
    setActiveModelIndex(index);
    setEditEVAlertMessage('');
    const response = await fetch(
      `/api/compare/getTrims/${selectedEVDefaultDetails.year}/${selectedEVDefaultDetails.make}/${model}`
    );
    if (response.ok) {
      const trims = await response.json();
      const newTrimList = trims.map((trim: { Trim: string; MSRP: number }) => {
        return {
          ...trim,
          Year: selectedEVDefaultDetails.year,
          Model: model,
          Make: selectedEVDefaultDetails.make,
        };
      });
      setTrimList(newTrimList);
      setActiveTrimIndex(-1);
    }
  }

  const handleTrimClick = useCallback(
    (trim: string, MSRP: number, index: number) => {
      setActiveTrimIndex(index);
      setEditEVAlertMessage('');
      const tempDefaultDetails = selectedEVDefaultDetails;
      setSelectedEVDefaultDetails((prevState) => ({
        ...prevState,
        trim,
        MSRP,
      }));
      if (showComparisonDetailPage) {
        setSelectedSecondEVDetails(() => ({
          ...tempDefaultDetails,
          trim,
          MSRP,
        }));
      } else {
        setSelectedFirstEVDetails(() => ({
          ...tempDefaultDetails,
          trim,
          MSRP,
        }));
      }
    },
    [selectedEVDefaultDetails, showComparisonDetailPage]
  );

  const goToStepTwo = useCallback(() => {
    setShowComparisonDetailPage(true);
    setActiveModelIndex(-1);
    setActiveTrimIndex(1);
    setSelectedFirstEVDetails(firstChoice || selectedEVDefaultDetails);
    setSelectedEVDefaultDetails(DEFAULT_COMPARISON_EV_DETAILS);
    setEditEVAlertMessage('');
  }, [selectedEVDefaultDetails, firstChoice]);

  const handleSelectSecondEVClick = useCallback(async () => {
    const response = await fetch(
      `/api/compare/getVehicleStylesData/?year=${selectedEVDefaultDetails.year}&make=${
        selectedEVDefaultDetails.make
      }&model=${selectedEVDefaultDetails.model}&trim=${encodeURIComponent(
        selectedEVDefaultDetails.trim
      )}`
    );
    if (response.ok) {
      goToStepTwo();
    } else {
      setEditEVAlertMessage(SELECT_ANOTHER_EV);
    }
  }, [selectedEVDefaultDetails, goToStepTwo]);

  const handleReset = useCallback(() => {
    setActiveModelIndex(-1);
    setActiveTrimIndex(1);
    if (!firstChoice) {
      setShowComparisonDetailPage(false);
    }
    setSelectedEVDefaultDetails(DEFAULT_COMPARISON_EV_DETAILS);
    if (!firstChoice) {
      setSelectedFirstEVDetails(DEFAULT_COMPARISON_EV_DETAILS);
    }
    setSelectedSecondEVDetails(DEFAULT_COMPARISON_EV_DETAILS);
    setShowReset(false);
    setEditEVAlertMessage('');
  }, [firstChoice]);

  function handleClose() {
    goToNextModal(-1);
    handleReset();
  }

  const onCompleteFlow = useCallback(async () => {
    const responseSuccessful = await onSubmit({
      selectedDefaultDetails: selectedEVDefaultDetails,
      firstEV: firstEVToCompare,
      secondEV: selectedSecondEVDetails,
    });

    if (responseSuccessful) {
      setEditEVAlertMessage('');
      if (onClose) {
        onClose();
      }
    } else {
      setEditEVAlertMessage(SELECT_ANOTHER_EV);
    }
  }, [
    firstEVToCompare,
    selectedEVDefaultDetails,
    selectedSecondEVDetails,
    onSubmit,
    onClose,
  ]);

  useEffect(() => {
    const isFirstEvChosen = !!firstChoice && modalStep === 1;
    if (isFirstEvChosen) {
      goToNextModal(2);
      goToStepTwo();
    }
  }, [modalStep, firstChoice, goToStepTwo, goToNextModal]);

  useEffect(() => {
    if (trimList.length && activeTrimIndex < 0) {
      handleTrimClick(trimList[0].Trim, trimList[0].MSRP, 0);
    }
  }, [trimList, activeTrimIndex, handleTrimClick]);

  return (
    <PopOutModal
      open={modalStep > 0}
      onClose={handleClose}
      className="h-full w-full overflow-hidden !bg-white !px-[16px] !pb-[10px] !pt-[20px] m:h-[95%] l:!px-[32px] l:!pb-[20px]"
    >
      <EVCompareModel
        modelHeader={
          showComparisonDetailPage ? SELECT_SECOND_EV : SELECT_FIRST_EV
        }
        modelButtonLabel={
          showComparisonDetailPage ? COMPARE_EVS : SELECT_SECOND_EV
        }
        selectedFirstEVStyle={
          showComparisonDetailPage
            ? selectedSecondEVStyle
            : selectedFirstEVStyle
        }
        selectedSecondEVStyle={
          showComparisonDetailPage
            ? selectedFirstEVStyle
            : selectedSecondEVStyle
        }
        firstEVIconStyle={
          showComparisonDetailPage ? secondEVIconStyle : firstEVIconStyle
        }
        secondEVIconStyle={
          showComparisonDetailPage ? firstEVIconStyle : secondEVIconStyle
        }
        showComparisonDetailPage={showComparisonDetailPage}
        selectedFirstEVDetails={firstEVToCompare}
        selectedSecondEVDetails={selectedSecondEVDetails}
        selectedEVDefaultDetails={selectedEVDefaultDetails}
        makes={makes}
        makeEVOptions={makeEVOptions}
        yearList={yearList}
        activeModelIndex={activeModelIndex}
        trimList={trimList}
        activeTrimIndex={activeTrimIndex}
        showReset={showReset}
        handleMakeClick={handleMakeClick}
        handleMakeChange={handleMakeChange}
        handleBadgeClick={handleBadgeClick}
        handleModelClick={handleModelClick}
        handleReset={handleReset}
        handleTrimClick={handleTrimClick}
        onCompleteFlow={onCompleteFlow}
        editEVAlertMessage={editEVAlertMessage}
        handleSelectEVClick={handleSelectSecondEVClick}
      />
    </PopOutModal>
  );
};
